import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { usePoll } from "@/components/hooks/usePoll.hook";
import { isArray } from "lodash/fp";
import { useContext, useEffect } from "react";
import { SSEContext } from "./SSEContext";

const DEFAULT_PARSER = input => {
    try {
        return JSON.parse(input);
    } catch (error) {
        console.warn("SSE parsing Error", input, error.message);
        return {};
    }
};

const useSSE = ({
    type,
    eventHandler,
    parser = DEFAULT_PARSER,
    fallbackAsyncFn = () => new Promise(() => {}),
}) => {
    const { eventSource, runFallback } = useContext(SSEContext);

    const handler = useCurrentHandler(event => {
        return eventHandler(parser(event.detail.data));
    });

    const dispatchPolledEvent = data =>
        eventSource.dispatchEvent(
            new CustomEvent(type, { detail: { data: JSON.stringify(data) } }),
        );

    if (runFallback) {
        console.warn("Running SSE in fallback mode");
    }

    usePoll({
        asyncFn: fallbackAsyncFn,
        onSuccess: data => {
            if (isArray(data)) {
                data.forEach(dispatchPolledEvent);
            } else {
                dispatchPolledEvent(data);
            }
        },
        interval: 2000,
        runImmediately: false,
        canStart: runFallback,
    });

    useEffect(() => {
        if (eventSource) {
            eventSource.addEventListener(type, handler);

            // TODO: check that handler is not removed completely when effect reruns (exec order of effect/cleanupFn)
            return () => eventSource.removeEventListener(type, handler);
        }
    }, [eventSource, handler, type]);
};

export default useSSE;
