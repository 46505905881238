import { Button, Gap, H4 } from "@/components/DesignSystem";
import { EntityModal } from "@/components/DesignSystem/EntityEditorTable/EntityModal";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { addActionMenuRender } from "@/components/TableWithPreferences/utils";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { t } from "@/translations";
import { PlusOutlined } from "@ant-design/icons";
import { identity, merge, pullAt } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import uuid from "uuid/v4";

const CREATE_CREATE_COLUMNS_WITH_ACTION_MENU =
    ({ columns, confirmRemove = {} }) =>
    ({ showEditModal, onRemove, disabled }) =>
        addActionMenuRender(columns, record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: disabled
                            ? t("general.show-detail")
                            : t("general.edit"),
                        onClick: showEditModal,
                    },
                    {
                        visible: !disabled,
                        title: t("general.delete"),
                        confirm: {
                            title: "Delete Record",
                            message: t(
                                "general.do-you-really-want-to-remove-name",
                                { name: record.name },
                            ),
                            okText: t("general.delete"),
                            ...confirmRemove,
                            onConfirm: onRemove,
                        },
                        color: "red",
                    },
                ]}
            />
        ));

const EDIT_MODAL_PROPS = {
    title: t("general.edit"),
    description: undefined,
    submitButtonLabel: t("general.edit"),
};

const ADD_MODAL_PROPS = {
    title: t("general.add"),
    description: undefined,
    submitButtonLabel: t("general.add"),
};

const defaultGetRecordId = row => uuid();

export const EntityEditorTable = ({
    value: formValue,
    onChange,
    columns,
    disabled,
    addModalProps = {},
    editModalProps = {},
    confirmRemove = {},
    createColumns = CREATE_CREATE_COLUMNS_WITH_ACTION_MENU({
        columns,
        confirmRemove,
    }),
    rowKey = "_id",
    getRecordId = defaultGetRecordId,
    title,
    addButtonLabel,
    addButtonProps = { label: addButtonLabel },
    ModalComponent = EntityModal,
    getModalExtraProps,
    rowHeight,
    addAlertFn = identity,
}) => {
    const defaultedValue = useMemo(() => formValue || [], [formValue]); // undefined or null
    const valueWithIds = useMemo(
        () =>
            defaultedValue.map(item => merge(item, { _id: getRecordId(item) })),
        [defaultedValue, getRecordId],
    );

    const modal = useDetailDrawerState(false);
    const showAddModal = useCallback(() => {
        modal.show({ initialValues: undefined, _id: undefined });
    }, [modal]);
    const showEditModal = useCallback(
        record => {
            // table map rows, cannot be compared
            // const _index = value.findIndex(item => item === record);
            // console.log("[showEditModal]", { record });
            modal.show({
                initialValues: record,
                _id: record._id,
            });
        },
        [modal],
    );
    const onRemove = useCallback(
        record => {
            const index = valueWithIds.findIndex(
                item => item._id === record?._id,
            );
            const newValue = pullAt([index], defaultedValue);
            // console.log("[onRemove]", { index, newValue, defaultedValue, valueWithIds, });
            onChange(newValue);
        },
        [defaultedValue, onChange, valueWithIds],
    );
    const onAdd = useCallback(
        values => {
            // uniqWith(prop(rowKey)), // TODO: unique validator
            onChange([...defaultedValue, values]);
            modal.hide();
        },
        [modal, onChange, defaultedValue],
    );
    const onEdit = useCallback(
        values => {
            const index = valueWithIds.findIndex(
                item => item._id === modal.record?._id,
            );
            // const index = modal.record?.index;
            const newValue = defaultedValue.slice();
            newValue.splice(index, 1, values);
            onChange(newValue);
            modal.hide();
        },
        [defaultedValue, modal, onChange, valueWithIds],
    );
    const onSubmit = modal.record?._id ? onEdit : onAdd;

    const columnsWithActionMenu = useMemo(
        () => createColumns({ onRemove, showEditModal, disabled }),
        [createColumns, onRemove, showEditModal, disabled],
    );
    const isModalOpen = !!modal.record;
    const isAddModalOpen = isModalOpen && !modal.record?._id;
    const isEditModalOpen = isModalOpen && !!modal.record?._id;
    const modalExtraProps = {
        ...(isAddModalOpen ? { ...ADD_MODAL_PROPS, ...addModalProps } : {}),
        ...(isEditModalOpen ? { ...EDIT_MODAL_PROPS, ...editModalProps } : {}),
        ...getModalExtraProps?.({
            initialValues: modal.record?.initialValues,
            isEdit: isEditModalOpen,
            disabled,
        }),
    };
    // console.log("[EntityEditorTable.rndr]", { record: modal.record, value, columnsWithActionMenu, });

    return (
        <div style={{ width: "100%" }}>
            {title && (
                <>
                    <H4>{title}</H4>
                    <Gap size="small" />
                </>
            )}
            <TableLocalFiltered
                columns={columnsWithActionMenu}
                dataSource={addAlertFn(valueWithIds)}
                pagination={false}
                rowKey={rowKey}
                rowHeight={rowHeight}
            />
            <Gap />
            <Button
                icon={PlusOutlined}
                label={addButtonLabel}
                onClick={showAddModal}
                {...addButtonProps}
                disabled={addButtonProps?.disabled ?? disabled}
            />

            {isModalOpen && (
                <ModalComponent
                    visible={isModalOpen}
                    onClose={modal.hide}
                    columns={columns}
                    isEdit={isEditModalOpen}
                    initialValues={modal.record?.initialValues}
                    onSubmit={onSubmit}
                    valueWithIds={valueWithIds}
                    editRecordId={modal.record?._id}
                    disabled={disabled}
                    modalExtraProps={modalExtraProps}
                />
            )}
        </div>
    );
};

EntityEditorTable.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    rowKey: PropTypes.string,
    columns: PropTypes.array,
    createColumns: PropTypes.func,
    title: PropTypes.node,
    addButtonLabel: PropTypes.node.isRequired,
    addButtonProps: PropTypes.object,
    fields: PropTypes.array,
    getRecordId: PropTypes.func,
    ModalComponent: PropTypes.elementType,
    getModalExtraProps: PropTypes.func,
    rowHeight: PropTypes.number,
};
